<template>
  <div>
    <h2 class="font-weight-bolder mb-2 hGoBack">
      <span @click="$router.go(-1)">
        <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
          <feather-icon icon="ArrowLeftCircleIcon" size="35" />
        </b-button>
      </span> Registrar Incidencia
    </h2>
    <b-card>
      <!-- form -->
      <validation-observer ref="createIncidence">
        <b-form class="mt-2" @submit.prevent="handleSubmit" @keydown.enter="handleSubmit">
          <b-row>
            <b-col sm="6">
              <!-- name -->
              <validation-provider #default="{ errors }" :name="'Incidencia'" rules="required">
                <b-form-group :label="'Incidencia'" label-for="client-name">
                  <b-form-input v-model="name" name="name" :placeholder="'Incidencia'" />
                </b-form-group>
                <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

              <!-- date -->
              <b-form-group :label="'Fecha'" label-for="date">
                <flat-pickr v-model="date" name="date" class="form-control" />
              </b-form-group>

              <!-- description -->
              <b-form-group :label="'Descripción'" label-for="product-description">
                <quill-editor v-model="description" />
              </b-form-group>

            </b-col>
            <b-col cols="6">
              <!-- client -->
              <b-form-group :label="'Cliente'" label-for="project">
                <b-form-input v-model="asset.client.name" name="client" disabled />
              </b-form-group>

              <!-- project -->
              <b-form-group :label="'Obra'" label-for="project">
                <b-form-input v-model="asset.project.name" name="project" disabled />
              </b-form-group>

              <!-- asset -->
              <b-form-group :label="'Equipo'" label-for="asset">
                <b-form-input v-model="asset.productNameWithIni" name="asset" disabled />
              </b-form-group>

              

            </b-col>

            <b-col cols="12">
              <b-form-group :label="'Documentos'" label-for="documents">
                <ImageDropzone ref="documents" :files="files" />
              </b-form-group>
            </b-col>
            <b-col cols="12 text-right">
              <b-button type="submit" variant="primary" class="mt-2 mr-1">
                Guardar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BLink, BFormCheckboxGroup, BFormCheckbox } from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import ImageDropzone from '@/components/elements/ImageDropzone/ImageDropzone.vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { FlatPickrToTimestamp, TimestampToFlatPickr } from '@/libs/helpers'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckboxGroup,
    BFormCheckbox,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    ImageDropzone,
    BLink,
    quillEditor,
    vSelect,
    flatPickr
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      asset_id: this.$route.params.asset_id,
      name: '',
      description: '',
      date: '',
      files: [],
      required,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/getUser',
      tecnicos: 'users/getItems',
      // selectMaintenances: "maintenances/getItems",
      asset: 'assets/getAsset',


    }),
  },
  methods: {
    ...mapActions({
      create: 'incidences/create',
      listTecnicos: 'users/getListUsers',
      // getListMaintenances: 'maintenances/getListMaintenances',
      getAsset: 'assets/getAsset',


    }),

    handleSubmit() {
      this.$refs.createIncidence.validate().then(success => {
        console.log('revisión', success)
        if (success) {
          const formData = this.createFormData()
          if (formData) {
            this.create({ incidence: formData })
          } else {
          this.$refs.errorField.scrollIntoView({
            behavior: 'smooth',
          block: 'end', 
          });
        }
        }
      })
    },
    createFormData() {
      const data = this.$refs.documents.getFormData('documents')

      data.append('name', this.name)
      data.append('asset_id', this.asset.id)

      if (this.description !== '' || this.description !== null) {
        data.append('description', this.description)
      }

      if (this.date) {
        data.append('date', FlatPickrToTimestamp(this.date))
      }

      // if (this.maintenance) {
      //   data.append('maintenance_id', this.maintenance.id)
      // }

      return data
    },
  },

  async created() {
    await this.getAsset(this.$route.params.asset_id)
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
